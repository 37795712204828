
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { getUser } from '@/services/api';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  setup() {
    const userId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref();
    const { t } = useI18n();

    onMounted(async() => {
      const res = await getUser({ userId });
      data.value = res.data;
    });

    return {
      data,
      t,
      page
    };
  }
});
